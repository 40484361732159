import React from 'react'
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
// import HubspotForm from 'react-hubspot-form';
import HomeLayout from '../components/HomeLayout';
import MailchimpForm from '../components/MailchimpForm';

const SignUpHeader = styled.div`
  color: #CD5B49 !important;
  text-align: center;
  margin-bottom: .5em !important;
  font-weight: bold;
  max-width: 260px;
  margin: 0 auto !important;
`;
const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;
const SignUp = () => (
  <HomeLayout>
    <StyledContainer>
      <SignUpHeader className='title text-h3 tx-color-3'>Sign up for our Weekly Digest!</SignUpHeader>
      <MailchimpForm />
      {/* <HubspotForm
        portalId='4359972'
        formId='e99a1f22-d3f5-45a8-8c0b-5a3296ca644c'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      /> */}
    </StyledContainer>
  </HomeLayout>
)

export default SignUp
